import React from "react";
import { NavLink } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useDispatch } from "react-redux";
import { SectionsOfPagesThunk } from "../../RTK/Thunk/SectionsOfPagesThunk";
import { setCurrentPageId } from "../../RTK/Reducers/PagesReducer";

const AccordionHeader = ({ handleDrawerToggle, title, icon, navItemsLink }) => {
	const dispatch = useDispatch();

	return (
		navItemsLink?.length !== 0 && (
			<div className=' w-full '>
				<Accordion className='!border-b-[1px] !rounded-none  !border-b-color_11 !bg-transparent !border-r-0 !border-l-0 !border-t-0 !shadow-none'>
					<AccordionSummary
						expandIcon={
							<ExpandMoreIcon
								className={`!text-[30px] !text-color_06 !font-[600] `}
							/>
						}
						aria-controls='panel1a-content'
						id='panel1a-header'
						className='w-full !bg-color_01 !p-0'
						style={{ direction: "rtl" }}>
						<div className='flex justify-start gap-[10px] items-center w-full transition-[0.3s]  !text-color_06  !text-[20px] !font-medium text-center'>
							<span className='w-6'>
								<img src={icon} alt='' />
							</span>
							{title}
						</div>
					</AccordionSummary>

					<AccordionDetails sx={{ padding: "0" }}>
						{navItemsLink?.map((item) => {
							if (item?.status && item?.has_sections) {
								return (
									<div
										key={item?.id}
										className='last:!border-none border-b-[1px] border-b-color_11 !bg-color_01 px-[18px] py-4'>
										<NavLink
											onClick={() => {
												handleDrawerToggle();
												dispatch(SectionsOfPagesThunk(item?.id));
												dispatch(setCurrentPageId(item));
											}}
											to={`/SupPage/${item?.id}/${item?.name}`}
											className=' gap-[8px] flex justify-start items-center w-full transition-[0.3s]  !text-color_06  !text-lg !font-normal text-right'>
											<span className='w-6'>
												<img src={icon} alt='' />
											</span>
											{item?.name}
										</NavLink>
									</div>
								);
							}
						})}
					</AccordionDetails>
				</Accordion>
			</div>
		)
	);
};

export default AccordionHeader;
