import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./VideoSliderBox.css";

// import required modules
import { FaPlay } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

import moment from "moment/moment";

const VideoSliderBox = ({ sliders }) => {
	const [ShowVideo, setShowVideo] = useState(false);
	const [videoUrl, setVideoUrl] = useState("");
	return (
		<>
			<Swiper
				slidesPerView={1}
				spaceBetween={26}
				centeredSlides={true}
				dir='rtl'
				loop={true}
				pagination={{
					dynamicBullets: true,
				}}
				breakpoints={{
					640: {
						slidesPerView: 1,
					},
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
				}}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
				className='VideoSliderBox !p-[15px]'>
				{sliders?.map((el, index) => {
					return (
						<SwiperSlide
							key={index}
							className=' pb-[50px] flex justify-center bg-white  items-center '>
							<Fade
								direction={"left"}
								triggerOnce
								delay={index * 10}
								cascade
								damping={1}
								duration={800}
								className=' w-full  flex items-center justify-center max-w-[450px] h-[450px] max-h-[450px] min-h-[400px] '>
								<div
									onClick={() => {
										const youtubeUrl = `${el?.link}`;
										const parts = youtubeUrl.split("v=");

										const videoId = parts[1];

										setShowVideo(true);
										setVideoUrl(videoId);
									}}
									className='flex  w-full h-full cursor-pointer pb-[10px] group/item bg-color_10  justify-start text-center items-center flex-col gap-[10px] shadow-md  transition-[0.3s] hover:shadow-lg  rounded-[6px] '>
									<div className='img-box w-full h-full relative max-h-[310px]'>
										<img
											src={el.video}
											alt=''
											className=' rounded-b-[4px]  w-full h-full object-cover'
										/>
										<div className=' shadow-sm shadow-[#124677] bottom-[-35px] group-hover/item:animate-ping left-[50%] translate-x-[-50%] flex group-hover/item:w-[80px] group-hover/item:h-[80px]  group-hover/item:bg-color_02  w-[70px] h-[70px] rounded-full bg-color_03  transition-[0.3s] justify-center items-center absolute'>
											<FaPlay className='!text-[22px] font-medium text-color_06  ' />
										</div>
									</div>
									<div className='flex mt-[20px] w-full max-w-[310px] justify-center items-center flex-col gap-[10px]  py-[20px]  min-h-[102px]'>
										<h5 className='text-[15px]  font-[500] text-color_02 '>
											{/* {moment(el?.created_at).format("LL")} */}
											{moment(el?.created_at).format("LL")}
										</h5>
										<p
											className='text-[16px]   font-[500] text-color_08 line-clamp-text'
											dangerouslySetInnerHTML={{ __html: el.text }}></p>
									</div>
								</div>
							</Fade>
						</SwiperSlide>
					);
				})}

				{ShowVideo && (
					<VideoPlayer
						{...{ ShowVideo, setShowVideo, videoUrl, setVideoUrl }}
					/>
				)}
			</Swiper>
		</>
	);
};

export default VideoSliderBox;
